import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Settings} from "../../../Settings/Settings";
import './Package.scss';
import {EventDispatcher} from "../../../Events/Dispatcher";
import PackageInfo from "./PackageInfo";
import {BookingData} from "../../Data/BookingData";
import cx from 'classnames';
import Button from "../../../Form/Element/Button";
import FormElementText from "../../../Form/Element/Text";

export default function Package(props) {
    let option = props.option;
    return <Fragment>
        {option.variations.data.map((variation) => {
            let current_option = props.booking.variations.data.find((booking_var) => {
                return booking_var.variation_id === variation.variation_id;
            });
            let image_url = variation.image.data.id ? variation.image.data.url : option.image.data.url;
            let className = option.name.replace(/ /g, '-').toLowerCase();
            return <div className={cx('product', {'product--no-image': !image_url}, 'product--' + className)}
                        key={variation.id}>
                <div className="grid">
                    {image_url ? <div className="grid__item">
                        <img src={image_url} alt={option.name}/>
                    </div> : null}
                    <div className="grid__item item-description">
                        <p className="product__title">{variation.name} <i className="fa fa-info" onClick={(e) => {
                            EventDispatcher.dispatch('open-modal', {
                                content: <PackageInfo option={option} variation={variation} booking={props.booking}/>,
                                top: e.pageY
                            });
                        }}/></p>
                        {variation.description ? <p className="product__desc">{variation.description}</p> : null}
                        <p className="product__price">{Settings.currencyFormatter.format(variation.calculated_price)} {option.price_per_booking === false ? 'pp' : ''}</p>
                    </div>
                    <div className="grid__item">
                        {current_option ? <Fragment>
                            <div className="grid">
                                {props.booking.mix_match_packages === false && option.display_type === 'package' ?
                                    <Fragment>
                                        <div className="grid__item">
                                            <Button text={<i className="fa fa-check"/>} name="add-product"
                                                    variant="circular" onClick={() => {
                                                BookingData.updateVariation(variation.id, 0);
                                            }}/>
                                        </div>
                                    </Fragment> :
                                    <Fragment>
                                        <div className="grid__item">
                                            <Button text={<i className="fa fa-minus"/>} name="add-product"
                                                    onClick={() => {
                                                        BookingData.updateVariation(variation.id, current_option.quantity - 1);
                                                    }} variant="circular"/>
                                        </div>
                                        <div className="grid__item">
                                            <FormElementText name="count" value={current_option.quantity}
                                                             defferredUpdate={true}
                                                             update_on_props={true}
                                                             wrapperClass="field--circular" onChange={(e) => {
                                                BookingData.updateVariation(variation.id, e.target.value);
                                            }}/>

                                        </div>
                                        <div className="grid__item">
                                            <Button text={<i className="fa fa-plus"/>} name="add-product"
                                                    onClick={() => {
                                                        BookingData.updateVariation(variation.id, current_option.quantity + 1);
                                                    }} variant="circular"/>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </Fragment> : <Fragment>
                            <div className="grid">
                                <div className="grid__item">
                                    <Button text={<i className="fa fa-plus"/>} name="add-product" onClick={() => {
                                        let qty = 1;
                                        if (option.price_per_booking === false && (props.booking.variations.data.length === 0 || props.booking.mix_match_packages === false)) {
                                            qty = props.booking.no_people;
                                        }
                                        BookingData.updateVariation(variation.id, qty);
                                    }} variant="circular"/>
                                </div>
                            </div>
                        </Fragment>}
                    </div>
                </div>
            </div>
        })}
    </Fragment>
}

Package.propTypes = {
    option: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired
}