import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ManageLayout from "../Bookings/Manage/ManageLayout";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {NotificationManager} from "../Notifications/NotifcationManager";
import AppModal from "../Utility/Modal/AppModal";
import Loading from "../Utility/Loading/Loading";
import {BookingData} from "../Bookings/Data/BookingData";
import LiveChat from "./LiveChat/LiveChat";

export default function PortalPage(props) {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(null);
    useEffect(() => {
        if (props.requires_login && !user) {
            BookingData.checkUser((data) => {
                if (data === false) {
                    document.location = '/login';
                    return;
                }
                setUser(data);
                setLoaded(true);
            })
        } else {
            setLoaded(true);
        }
        document.getElementById('main_body').className = document.getElementById('main_body').className + ' bookings-portal';
        const sub_id = NotificationManager.subscribe((message) => {
            if (message.type === 'error') {
                toast.error(message.message);
            }
            if (message.type === 'info') {
                toast.info(message.message);
            }
            if (message.type === 'success') {
                toast.success(message.message);
            }
        });
        return () => {
            NotificationManager.unsubscribe(sub_id);
        }
    }, []);
    if (!loaded) {
        return <Loading/>
    }
    return <Fragment>
        <ToastContainer/>
        <ManageLayout name={props.name} user={user} variant={props.variant}>
            <AppModal/>
            {props.component}
        </ManageLayout>
        {user?<LiveChat />:null}
    </Fragment>
}

PortalPage.propTypes = {
    component: PropTypes.object.isRequired,
    requires_login: PropTypes.bool
}